@import "/assets/styles/utils.scss";

.input-field {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__label {
        font-size: 12px;
        line-height: 1;
    }

    &__container {
        position: relative;
    }

    &__input {
        height: 100%;
        width: 100%;

        display: flex;
        width: 100%;
        height: 52px;
        padding: 0px 16px;
        box-sizing: border-box;
        border-radius: var(--cornerRadius150, 12px);
        border: 1px solid var(--Border-borderOpaque, #E8E8E8);
        background: var(--Extensions-surfaceOverlayPrimary, #FFF);
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.03);
        font-size: 1em;

        &:focus-visible {
            outline: none;
            border: 1px solid var(--gray-90);
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.09);
        }

        &--phone {
            padding-left: calc(16px + 52px);
        }
        &--pill {
            border-radius: 9999px;
            padding-top: .5em;
            padding-bottom: .5em;
            padding-right: 140px;
            padding-left: 24px;
            height: 62px;

            + button {
                position: absolute;
                top: .5em;
                right: .5em;
            }
        }
    }

    &__phone-prefix {
        width: 52px;
        position: absolute;
        top: 0;
        left: 16px;
        bottom: 0;
        display: flex;
        align-items: center;
        :global {
            .select-search-container {
                box-sizing: border-box;
                width: 100%;

                // display: flex;
                // position: relative;
                // align-items: center;
                // justify-content: center;
                // margin-right: 0.125em;
                // padding-left: 0.5em;
                // padding-right: 0;
                // width: 55px;
            }

            .select-search-container *,
            .select-search-container *::after,
            .select-search-container *::before {
                box-sizing: inherit;
            }

            .select-search-input {
                font-size: em(16);
                max-width: 100%;
            }

            .select-search-is-multiple .select-search-input {
                margin-bottom: -2px;
            }

            .select-search-is-multiple .select-search-input {
                border-radius: 3px 3px 0 0;
            }

            .select-search-input[readonly] {
                cursor: pointer;
            }

            .select-search-is-disabled .select-search-input {
                cursor: not-allowed;
            }

            .select-search-select {
                background: white;
                padding: 4px 0;
                box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
                border: 2px solid var(--select-search-border);
                overflow: auto;
                max-height: 390px;
                min-width: 360px;
            }

            .select-search-container:not(.select-search-is-multiple)
                .select-search-select {
                position: absolute;
                z-index: 2;
                top: calc(100% + 12px);
                right: 0;
                left: -16px;
                border-radius: 3px;
                display: none;
            }

            .select-search-container:not(.select-search-is-multiple).select-search-has-focus
                .select-search-select {
                display: block;
            }

            .select-search-options {
                list-style: none;
            }

            .select-search-option,
            .select-search-not-found {
                color: black;
                font-size: 1.25em;
                padding: 4px 14px;
                width: 100%;
                text-align: left;
                &:hover {
                }
            }

            .select-search-option:disabled {
                opacity: 0.5;
                cursor: not-allowed;
                background: transparent !important;
            }

            .select-search-is-highlighted,
            .select-search-option:not(.select-search-is-selected):hover {
                color: black;
                background-color: var(--sky-20);
            }

            .select-search-is-selected {
                background-color: var(--marine);
                color: white;
            }

            .select-search-group-header {
                text-transform: uppercase;
                background: var(--select-search-border);
                color: var(--select-search-subtle-text);
                letter-spacing: 0.1rem;
                padding: 10px 16px;
            }
        }
    }
}
