@use 'sass:math';
@import '/assets/styles/utils.scss';

.download-overlay {
    $self: &;
    display: block;

    &__content {
        max-width: 660px;
        margin: 0 auto;
    }

    &__button {
        padding: 6px 16px;
    }
    @media (orientation: portrait) {
        :global(.doc-frontpage) &__button {
            transition: opacity 0.2s ease-in;
            opacity: 0;
        }
        :global(.scrolled-past-top-module) :global(.doc-frontpage) &__button {
            opacity: 1;
        }
    }

    &__popup {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        // background: linear-gradient(180deg, #00D63C 0%, #80F64D 100%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: black;
        transition: opacity .3s ease-in-out;
        opacity: 0;
        pointer-events: none;
        &--open {
            opacity: 1;
            pointer-events: all;
        }

        &--partly {
            background: transparent !important;

            #{$self}__close {
                z-index: 1001;
                @media (max-width: 768px) {
                    top: 20px;
                    bottom: 20px;
                }
            }
            #{$self}__backdrop {
                position: fixed;
                z-index: 999;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: black;
                opacity: 0.5;
                animation: fadein 0.3s;
                animation-fill-mode: forward;
                &--closed {
                    display: none;
                }
            }
            #{$self}__content {
                position: fixed;
                background-color: transparent;
                top: 50%;
                left: 50%;
                right: auto;
                bottom: auto;
                z-index: 1000;
                transform: translate(-50%, -50%);
                margin: 0;

                max-width: 960px;
                // max-height: 100%;
                // border-radius: 12px;
                overflow: hidden;
            }

            #{$self}__inner-content {
                display: flex;
                max-height: calc(100dvh - (2* var(--margin-spacing)));
                background: white;
                border-radius: 12px;
                overflow: hidden;

                :global(.stage) {
                    display: flex;
                    width: 400px;
                    flex-basis: 450px;
                    max-width: 100%;
                    max-height: 100%;
                    padding: 24px;
                    box-sizing: border-box;
                    overflow-y: scroll;

                    flex-direction: column;
                    align-items: flex-start;

                    flex-shrink: 0;
                }
                :global(.image-container) {
                    position: relative;

                    :global(img) {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                :global(.live-pulse)::before {
                    content: "";
                    display: inline-block;
                    background: black;
                    border-radius: 50%;
                    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
                    margin: 0 7px 0 10px;
                    height: 1.1em;
                    width: 1.1em;
                    transform: scale(1);
                    animation: pulse-green 2s infinite;
                    background: var(--accent);
                    box-shadow: 0 0 0 0 var(--accent);
                    animation: pulse-green 2.2s infinite;
                    vertical-align: middle;
                }

                @keyframes pulse-green {
                    0% {
                        transform: scale(0.95);
                        box-shadow: 0 0 0 0 rgba(0, 203, 57, 0.8);
                    }

                    70% {
                        transform: scale(1);
                        box-shadow: 0 0 0 6px rgba(0, 203, 57, 0);
                    }

                    100% {
                        transform: scale(0.95);
                        box-shadow: 0 0 0 0 rgba(0, 203, 57, 0);
                    }
                }

            }
        }
    }

    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    &__logo {
        position: absolute;
        top: em(22);
        left: em(16);

        &__svg {
            height: em(28);
        }
    }

    &__close {
        position: absolute;
        top: 24px;
        right: 24px;
        background-color: white;
        color: black;
    }

    &__mobile-title {
        text-align: center;
        margin-bottom: em(24, 32);
    }

    &__store {
        margin-top: 30px;
        img {
            height: 57px;
        }
    }

    @media (min-width: 768px) {
        &__store,
        &__mobile-title {
            display: none;
        }
    }

    &__send-sms {
        // display: none;

        // @media (min-width: 768px) {
        //     display: block;
        // }

        :global(.segment-private) & {
            :global(.button--pill) {
                // background: linear-gradient(180deg, #00D63C 0%, #80F64D 100%);
                // --hover-color: black;
                // background-color: black;
            }
        }
    }

    &__steps {
        margin: 3em 1.5em 0;
        display: grid;
        grid-template-rows: auto;
        grid-row-gap: 20px;

        @media (min-width: 768px) {
            margin: 3.5em auto 0;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 20px;
        }
    }

    &__step {
        display: flex;
        position: relative;
        z-index: 2;
        background-color: white;
        color: black;
        border-radius: 8px;
        padding: 20px;

        @media (min-width: 768px) {
            flex-direction: column;
            text-align: left;
        }
    }

    &__step__number {
        font-size: em(56);
        line-height: 100%;
        font-weight: 300;
        margin-bottom: 16px;

        @media (max-width: 767px) {
            min-width: 24%;
        }
    }

    &__step__text {
    }

    &__recaptcha-disclaimer {
        position: absolute;
        bottom: 8px;
        right: 8px;
        margin-top: em(8, 12);
        text-align: center;
        opacity: 0.5;

        @media (max-width: 767px) {
            display: none;
        }
    }

    &--segment-business {
        #{$self}__popup {
            background-color: white;
            color: black;
        }
        #{$self}__send-sms {
            :global(form > div[class*='__input__']) {
                border: 1px solid black;
            }
        }
        :global(li[class*='StepsModule_item__']) {
            border: 1px solid black;
        }
    }

    &__floating-cta {
        opacity: 0;
        transition: opacity 0.5s;
        position: fixed;
        z-index: 100;
        bottom: -1px;
        right: 0;
        left: 0;
        border-top: 1px solid var(--gray-60);
        background: white;

        :global([class*='button--pill']) {
            // box-shadow: 0px 0px 5px gray;
            padding: 6px 16px;
        }
        :global([class*='ButtonGroup']) {
            // width: 100%;
            justify-content: space-around;
            justify-content: flex-end;
            padding: 16px;
        }
    }

}

:global(.scrolled-past-top-module) .download-overlay__floating-cta {
    opacity: 1;
}
