.ribbon-module {
    $self: &;
    padding-top: 1em;
    padding-bottom: 1em;
    overflow: hidden;

    &__static__inner {
        display: flex;
        justify-content: space-between;
        overflow: visible;
        @media (max-width: 768px) {
            flex-direction: column;
            gap: 1em;
        }

        font-size: 1.1em;
        @media (min-width: 1300px) {
            font-size: 1.25em;
        }

        &__text {
            font-weight: 700;
        }
    }

    @media (min-width: 1024px) {
        padding-top: 1em;
        padding-bottom: 1em;
    }

    &__link {
        display: block;
    }

    &__inner {
        position: relative;
        display: flex;
        overflow: hidden;
        user-select: none;

        &__text {
            @media (max-width: 768px) {
                font-size: 1.2em;
            }
        }

        &__animating {
            flex-shrink: 0;
            display: flex;
            justify-content: space-around;
            gap: var(--gap);
            will-change: transform;
            animation: marquee var(--animation-speed) linear infinite;

            :global(svg) {
                height: 0.9em;
                margin: 0 1em;
                color: black;
            }
        }
    }
}

@keyframes marquee {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}
