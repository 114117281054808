@use 'sass:math';
@import '/assets/styles/utils.scss';

.menu {
    $menu: &;
    position: relative;
    height: 100%;
    display: flex;

    &__button {
        white-space: nowrap;
        color: var(--color);
        cursor: default;

        svg {
            height: 0.6em;
            margin-left: 7px;
            transition: transform 0.3s;
        }
    }

    &__popup {
        display: none;
        $self: &;
        position: absolute;
        background-color: white;
        width: min(25vw, 400px);
        height: auto;
        top: calc(100% + 0px);
        left: 1em;
        @media (min-width: 1280px) {
            left: 1.5em;
        }
        padding: 32px;
        color: black;
        box-sizing: border-box;
        // transform: translateX(-50%);
        z-index: 100;

        &--active {
            display: block;
        }

        &--big {
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: calc(var(--header-height) + 0px);
            padding-bottom: 80px;
            // left: 50%;
            // transform: translate(-50%, 0);
            #{$self}__list {
                max-width: 1920px;
                // padding: em(50) min(em(100), 6vw);
                flex-wrap: nowrap;
                gap: max(36px, 3.5vw);

                > * {
                    // flex-basis: 100%;
                    // max-width: 25vw;
                }
            }
        }

        &__list {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            z-index: 10;
        }

        &__sublist {
            display: grid;
            row-gap: 16px;
            column-gap: 32px;
            flex-wrap: wrap;
            grid-auto-flow: row;
            @media (min-width: 768px) {
                grid-auto-flow: column;
            }
            // max-height: 200px; // About 3 items tall

            &__item {
                $self: &;
                display: flex;
                gap: 14px;
                transition: color 0.3s;
                align-items: center;
                position: relative;
                padding: 4px 12px 4px 2px;
                // margin-left: -2px;
                position: relative;
                left: -2px;

                @media (hover: hover) {
                    &:hover {
                        background-color: var(--gray-30);
                        border-radius: 8px;
                        color: var(--accent);
                    }
                }

                &__media {
                    width: 26px;
                    height: 26px;
                    flex-shrink: 0;
                    background-color: var(--gray-30);
                    padding: 8px;
                    border-radius: 8px;
                }
                &__text {
                    display: flex;
                    flex-direction: column;
                    line-height: 1.5;
                    gap: 1px;
                }
                &__subtitle {
                    font-size: 0.6875em;
                    color: var(--gray-90);
                }

                button,
                a {
                    text-align: left;
                }

                a {
                    position: relative;
                }

                a:not([class*='arrow-']) {
                    display: inline-block;
                    line-height: 120%;
                    &:hover, &:focus {
                        opacity: 0.6;
                    }
                }

                &--feature {
                    // height: 250px;
                    // width: 250px;
                    max-width: 250px;
                    aspect-ratio: 1;
                    position: relative;

                    #{$self}__media {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        padding: 0;
                        overflow: hidden;

                        img {
                            object-fit: cover;
                        }
                    }
                    #{$self}__text {
                        color: white;
                        position: relative;
                        z-index: 1;
                        max-width: 100%;
                        text-align: center;
                        align-self: flex-start;
                        padding: 24px 16px;
                        box-sizing: border-box;
                    }
                    #{$self}__subtitle {
                        color: white;
                    }

                }

                &--new {
                    // padding-top: 0.5em;
                    margin-right: 30px;
                }

                p {
                    font-size: 0.7em;
                    position: absolute;
                    top: 0;
                    left: 100%;
                    margin-left: 0.4em;
                    transform: translateY(-50%);
                    padding: 0.1em 0.45em;
                    z-index: 1;
                    color: var(--primary-white);
                    border-radius: 4px;
                    // border-bottom-left-radius: 0;
                }
            }
        }

        &__background {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 0;
        }

        &__heading {
            margin-bottom: em(20, 16);
            display: block;
            font-weight: 600;
            color: var(--gray-90);
            padding-bottom: 8px;
            border-bottom: 1px solid var(--gray-60);
            display: flex;
            justify-content: space-between;
        }

        &__collector {
            margin-bottom: 40px;
            gap: 32px;
            display: flex;
            flex-direction: column;

            & > li {

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &__item {
            box-sizing: border-box;
            animation: itemin 0.2s both;
            flex: 1;
            @media (min-width: 768px) {
                flex-basis: 25%;
                max-width: 410px;
            }

            &--two-columns {
                @media (min-width: 768px) {
                    flex-basis: 50%;
                    max-width: 820px;
                }
            }
            &--feature {
                max-width: none;
                flex: none;
            }

            #{$self}--big & {
                margin-bottom: 0;
            }

            &__cta {
                font-size: 0.875em;
                margin-top: 24px;
            }
        }
    }
}

@keyframes itemin {
    from {
        opacity: 0;
        transform: translateY(1em);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
