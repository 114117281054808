@use 'sass:math';
@import '/assets/styles/utils.scss';

.rich-text {
    [role='button'],
    a {
        display: inline-flex;
        text-decoration: underline;
        font-weight: 500;
    }

    p,
    ul,
    ol,
    li {
        margin-top: 1em;
        margin-bottom: 1em;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    li {
        padding-left: 0.25em;
        margin-left: 1.5em;
        margin-bottom: 0.75em;
        margin-top: 0.75em;
    }

    ul li {
        list-style: none;
        position: relative;
        margin-left: 0;
        padding-left: calc(1.4em + 0.85em);
        &:not(:has(svg)) {
            &::before {
                content: '';
                width: 1.3125rem;
                height: 1.3125rem;
                display: block;
                position: absolute;
                left: 0;
                top: 3px;
                // transform: translate(0, -50%);
                background: center / contain no-repeat
                url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_2175_2164)'%3E%3Ccircle cx='12' cy='12' r='12' fill='black'/%3E%3Cpath d='M18 6C18 6 15.3074 7.43478 13.1538 10.7826C11.0003 14.1304 11 16.5217 11 17' stroke='white' stroke-width='1.5'/%3E%3Cpath d='M7 12C7.33333 12 8.66667 12.9091 9.66667 14.2728C10.6667 15.6364 11 16.5455 11 17' stroke='white' stroke-width='1.5'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2175_2164'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
            }
        }
        &:has(svg) {
            padding-left: calc(2.625em + 1em);
            margin-bottom: 24px;
            min-height: 2.625em;
            vertical-align: middle;
            display: flex;
            align-items: center;
        }
        > svg:first-child {
            position: absolute;
            left: 0;
            top: 0px;
            width: 2.625em;
            height: 2.625em;
            color: var(--accent);
            :global(.segment-private) & {
                color: inherit;
            }
            :global(.segment-business .bg-accent) & {
                color: black;
            }
            :global(.segment-business .text-white) & {
                color: white;
            }
        }
        &:has(*) > *::before {
            top: 0.3em;
        }
    }

    ol li {
        list-style-type: decimal;
    }
    em {
        font-style: italic;
    }
    strong {
        font-weight: 500;
    }
    h2,
    h3 {
        strong {
            font-weight: 600;
        }
        :global(.light-font-weight) {
            font-weight: 300;
        }
    }

    :global(.icon) {
        width: 1.5em;
        height: 1.5em;
        vertical-align: bottom;
    }
    &:global(.heading-100), &:global(.heading-200), &:global(.heading-300) {
        :global(.icon) {
            width: 1.1em;
            height: 1.1em;
        }
    }
}
