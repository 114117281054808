.main {
    :global(.doc-inAppPage) & {
        --header-height: 0;
    }
    // margin-top: var(--header-height);
    position: relative;
    z-index: 1;

    &--no-top-margin {
        margin-top: 0;

        :global(> div:first-child [class*="Container_container"]) {
            padding-top: var(--header-height);
            margin-top: calc(-1 * var(--header-height));
        }
    }
}
// SAS Stratosphere background
.news-ribbon {
    background: url('https://cdn.sanity.io/images/inp43svr/production/c39c3297ceadb7263d26506b121c18bece86de9b-1024x768.png?auto=format');
    background-position: center;
    background-size: 100% auto;
}
.dark-theme {
    position: relative;
    color: var(--primary-white);
    background-color: black;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: var(--page-theme-background-color);
    }
}

.has-background {
    background-size: 100% auto;
    background-repeat: repeat-y;
    background-position-y: top;
    background-position-x: center;
}

.shareit-theme {
    :root.button--pill {
        background-color: black;
    }
}
.doc-inAppPage {
    :root {
        --header-height: 0;
    }
}
